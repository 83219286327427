import SideLogo from 'assets/Icons/Logo/SideLogo';
import { useState } from 'react';
import styled from 'styled-components';
import { zIndex } from 'styles/Global/globalStyles';
import HospitalSideBar from '../Hospital/HospitalSideBar';
import { sideBarConstants } from 'constants/SideBarConstants';
import { BackgroundColor, BorderColor, PrimaryColor } from 'styles/Global/colorSemantic';
import { GNB_Button } from 'styles/Global/buttonStyles';
import { Radius } from 'styles/Global/objectStyles';
import DckIcon from 'assets/Icons/SideBarList/DckIcon';
import { useLoginStore } from 'store/useLoginDataStore';
import AdminSideBar from 'components/Admin/AdminSideBar';

const SideBar = ({ location }: { location: boolean }) => {
    const { loginData } = useLoginStore();

    const [selectedSideBar, setSelectedSideBar] = useState<number>(0);

    return (
        <Layout>
            <Container>
                <SideLogo />
                {location && (
                    <>
                        {sideBarConstants.map((list, idx) => {
                            return (
                                <GNB_Button key={idx} onClick={() => setSelectedSideBar(idx)} $idx={idx} selected={selectedSideBar}>
                                    <DckIcon />
                                    {list}
                                </GNB_Button>
                            );
                        })}
                    </>
                )}
            </Container>
            {location && <SideListLayout>{selectedSideBar === 0 && loginData.level !== 100 ? <HospitalSideBar /> : <AdminSideBar />}</SideListLayout>}
        </Layout>
    );
};

export default SideBar;

const Layout = styled.div`
    display: flex;
    min-height: 100vh;
    position: fixed;
    top: 0;
    z-index: ${zIndex.basic};
    background-color: ${PrimaryColor.color_primary_heavy};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 88px;
    padding-top: 25px;
    background-color: ${PrimaryColor.color_primary_heavy};
`;

const SideListLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 232px;
    padding: 20px 16px;
    border-radius: ${Radius.radius_24} 0px 0px ${Radius.radius_24};
    background-color: ${BackgroundColor.color_background_primary_neutral};
    border-right: 1px solid ${BorderColor.color_border_light};
`;
